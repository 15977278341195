import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to education</title>
);

export const EducationPage = ({
  data,
}: PageProps<Queries.EducationVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with education videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to education, including school and college
              or university. We have <Link to="/">lots of other videos</Link> if
              you&apos;re interested in other topics.
            </p>
            <p>
              Learning Chinese can be a daunting journey but it doesn&apos;t
              have to be. With the right resources, anyone can become
              conversational in the language. These videos about school,
              education and student life are designed to make the process easier
              by giving you access to the language used in these various
              contexts. You&apos;ll learn the words and phrases used to talk
              about education, student life and all the different aspects of
              school while also getting an insight into how school life works in
              China. Whether it&apos;s the classroom, the library or the
              cafeteria &mdash; you&apos;ll come away with a better
              understanding of the language and culture.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default EducationPage;

export const pageQuery = graphql`
  query EducationVideos {
    allVideosYaml(filter: { topics: { in: "education" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
